import * as React from 'react';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';

import { hexToRgb } from '../../styles/helpers/hexToRgb';
import theme from '../../styles/theme';
import LogoMedium from '../../components/icons/LogoMedium';
import ClapIcon from '../../components/icons/ClapIcon';
import { ProfileImage } from '../../styles/reusableComponents';
import { medias } from '../../styles/helpers/mediaQueries';

export interface IPostPreviewProps {
  title: string;
  url: string;
  totalClapCount?: number;
  subtitle?: string;
  previewImageId?: string;
  author?: {
    name: string;
    imageId: string;
    username: string;
  };
  authorName?: string;
  searchable?: string;
  index?: number;
}

const Wrapper = styled.article`
  margin: 2.5rem 1.25rem;
  flex: 1 0 280px;
  max-width: 430px;
  @media ${medias.tabletMax} {
    margin: 2.5rem 0;
    flex: 0 0 100%;
  }
  @media ${medias.largeMin} {
    flex: 1 0 320px;
  }
`;

const Title = styled.h3`
  line-height: 1.65;
  font-size: 1rem;
  margin: 1rem 0 0.25rem;
  a {
    text-decoration: none;
  }
`;

const Subtitle = styled.h4`
  line-height: 1.65;
  font-weight: 300;
  font-size: 1rem;
  margin: 0 0 0.5rem;
`;

const AuthorWrapper = styled.a`
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  text-decoration: none;
  > div {
    display: inline-block;
    position: relative;
    border-radius: 50%;
    margin-right: 0.5rem;
    ::after {
      content: '';
      border-radius: inherit;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: ${hexToRgb(theme.colors.primary, 0.5)};
      mix-blend-mode: multiply;
    }
  }
  img {
    box-shadow: ${theme.boxShadow.two};
    border-radius: inherit;
    max-width: 50px;
    display: block;
  }
`;

const ImageLink = styled.a`
  width: 100%;
  text-decoration: none;
  position: relative;
  border-radius: 5px;
  display: inline-block;
  transition: ${theme.transitions.slow};
  box-shadow: ${theme.boxShadow.three};
  img {
    transition: ${theme.transitions.slow};
    border-radius: inherit;
    display: block;
    filter: grayscale(1) brightness(0.75);
  }
  ::after {
    content: '';
    border-radius: inherit;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: ${({ color }: { color: string }) =>
      `radial-gradient(closest-side, ${hexToRgb(color, 0.7)}, ${hexToRgb(
        color,
        0.9
      )} 95%, ${hexToRgb(theme.colors.bg, 1)} 200%)`};
    mix-blend-mode: color;
  }
  :hover {
    transform: scale(1.03);
    box-shadow: ${theme.boxShadow.four};
  }
`;

const imageTextBaseStyles = `
  position: absolute;
  font-size: .8em;
  display: flex;
  align-items: center;
  color: white;
  font-weight: 500;
  line-height: 1;
  svg {
    width: 1.8em;
  }
`;

const CTAWrapper = styled.span`
  ${imageTextBaseStyles};
  right: 0.75rem;
  bottom: 0.75rem;
  svg {
    margin-left: 0.5rem;
    background: white;
  }
`;

const Claps = styled.span`
  ${imageTextBaseStyles};
  font-size: 1rem;
  left: 0.75rem;
  top: 0.75rem;
  svg {
    margin-right: 0.5rem;
  }
`;

const filterColors = ['#4A26D2', '#FF8811', '#E01A59'];

export const getPreviewImageURL = (id: string) =>
  `https://cdn-images-1.medium.com/fit/c/430/285/${id}`;

export const PostPreview: React.SFC<IPostPreviewProps> = props => {
  const {
    title,
    url,
    subtitle,
    totalClapCount,
    previewImageId,
    author,
    authorName,
  } = props;
  const authorImage =
    author && `https://cdn-images-1.medium.com/fit/c/75/75/${author.imageId}`;
  const authorUrl = author && `https://medium.com/@${author.username}`;
  return (
    <Wrapper property="itemListElement" typeof="BlogPosting">
      <ImageLink
        href={url}
        target="_blank"
        rel="noopener"
        color={
          props.index && props.index <= 2
            ? filterColors[props.index]
            : filterColors[0]
        }
      >
        {previewImageId ? (
          <LazyLoad offset={500} once={true} height={285} width="100%">
            <img
              alt={`Imagem em destaque para o post: ${title}`}
              src={getPreviewImageURL(previewImageId)}
              property="image"
            />
          </LazyLoad>
        ) : (
          // Se não tiver imagem, usar uma div vazia pra preencher o espaço
          <div style={{ paddingBottom: '56.25%' }} />
        )}
        {totalClapCount && (
          <Claps>
            <ClapIcon color="white" /> {totalClapCount}
          </Claps>
        )}
        <CTAWrapper>
          Leia no Medium <LogoMedium />
        </CTAWrapper>
      </ImageLink>
      <Title>
        <a href={url} target="_blank" rel="noopener noreferrer" property="name">
          {title}
        </a>
        <meta property="headline" content={title} />
        <meta property="url" content={url} />
      </Title>
      {subtitle && <Subtitle property="description">{subtitle}</Subtitle>}
      {author && (
        <AuthorWrapper
          href={authorUrl}
          target="_blank"
          rel="noopener"
          property="author"
          typeof="author"
        >
          <LazyLoad offset={500} once={true} height={50}>
            <ProfileImage>
              <img
                property="image"
                src={authorImage}
                alt={`Foto de ${author.name}`}
              />
            </ProfileImage>
          </LazyLoad>
          <meta property="url" content={authorUrl} />
          <span property="name">{author.name}</span>
        </AuthorWrapper>
      )}
      {authorName && <span property="name">{authorName}</span>}
    </Wrapper>
  );
};

PostPreview.displayName = 'PostPreview';

export default PostPreview;
