import styled from 'styled-components';
import { hexToRgb } from './helpers/hexToRgb';
import theme from './theme';

export const ProfileImage = styled.div`
  display: inline-block;
  position: relative;
  border-radius: 50%;
  margin-right: 0.5rem;
  ::after {
    content: '';
    border-radius: inherit;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: ${hexToRgb(theme.colors.primary, 0.5)};
    mix-blend-mode: multiply;
  }
  img,
  .gatsby-image-wrapper {
    box-shadow: ${theme.boxShadow.two};
    border-radius: inherit;
    max-width: 50px;
    display: block !important;
  }
`;
